
@font-face {
  font-family: 'Akkurat-Light';
  src: local('Akkurat'), url(./fonts/Akkurat-Light.otf) format('opentype');
  font-weight: 300;
}
@font-face {
  font-family: 'Akkurat-Normal';
  src: local('Akkurat'), url(./fonts/Akkurat-Normal.otf) format('opentype');
}
@font-face {
  font-family: 'MyriadPro';
  src: local('MyriadPro'), url(./fonts/MyriadPro-Regular.otf) format('opentype');
}
@font-face {
  font-family: 'Zodiak-Light';
  src: local('Zodiak'), url(./fonts/Zodiak-Light.otf) format('opentype');
}


html,body{
  margin: 0px;
  padding: 0px;
  background-color: #E1E9EB;
}

* {
  box-sizing: border-box;
}

.App {

  
  width: 100%;
  font-family: 'Akkurat-Light';
  scroll-snap-type: y mandatory;
  transition: all 800ms ease-in-out;
  overflow-y: scroll;

  header,section,footer{
    scroll-snap-align: start;
  }

  a{
    color: #333;
    text-decoration: none;
  }

  .font-normal{
    
    font-weight: normal;
    
  }

  
  .row-cta{
   
    padding: 12vh 0px 12vh;
    font-family: 'Zodiak-Light';
    font-size: 16pt;
    letter-spacing: 1px;
    text-align: center;
  }

  .row-image{
    padding: 18vh 0px 18vh;
    background-color: #ffffffc8;
    margin: 0px;
  }

  .footer{
    background-color: #E1E9EB;
    margin-top: 0px;
    margin-bottom: 0px;
    display: flex;
    width: 100vw;
    padding: 6vh 0px 6vh;

    @media (max-width: 900px) {
      p{
        padding-left: 22px;
        padding-right: 22px;
      }
    }

  }

  .colonne{
    display: flex;
    flex-direction: column;
    
    .col{
      width: 100%;
      margin-top: 22px;
    }
  }

  .center-align{
    text-align: center;
  }

  .uppercase{
    text-transform: uppercase;
  }

  .group-buttons{
    margin-top: 62px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    column-gap: 120px;

    a{
      text-decoration: none;
      text-transform: uppercase;
      &:hover{
        text-decoration: underline;
      }
    }
  }


  .container{
    width: 100%;
    max-width: 1400px;
    margin: 0px auto 0px;
    display: block;
  }

  form{
    display: flex;
    flex-direction: column;
    row-gap: 22px;
    align-items: center;

    input[type="email"]{
      padding: 1rem 3rem;
      text-align: center;
      font-size: 1.2rem;
      border-radius: 22px;
      border: 1px solid #434343;
      max-width: 400px;
    }
    input[type="submit"]{
      background: none;
      border: none;
      font-size: 1em;
      text-decoration: underline;
      font-family: 'Zodiak-Light';
      text-transform: uppercase;
      font-weight: 500;
      
    }

  }

  .content-image{
    display: flex;
    justify-content: center;
    overflow: hidden;

  }

  .App-header {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    overflow: hidden;
  }

  .text-layer{
    display: flex;
    justify-content: center;
    flex-direction: column;
    min-height: 98vh;
    border: 0px solid #333;
    width: 100%;

    .clip-video{
    
      width: 100%;
      height: 400px;
      @media (max-width: 425px) {
        height: 270px;
      }
      
      left: 0px;
      top: 0px;
      z-index: -1;
      border: 0px solid #333;
      display: flex;
      margin: 0px auto 0px;
      overflow: hidden;
      justify-content: center;
      align-items: center;
      
      video{
        display: block;
        object-fit:cover;
        width: 100%;
        height: 100%;
        z-index: -1;
      }
    }

    span{
      position: relative;
    }

    .label-payoff{
      align-self: flex-end;
    }

    .label-name{
      font-size: 1.8rem;
    }

  

    @media (max-width: 900px) {

      .label-name,
      .label-payoff{
        font-size: 1.8rem;
      }

      .label-name{
        margin-left: 22px; 
      }
      .label-payoff{
        margin-right: 22px; 
      }

     
    }

    @media (max-width: 425px) {

      
      .label-name,
      .label-payoff{
        font-size: 1.5em;
        margin-left: 12px;
        margin-right: 12px;
      }
      
    }

    

    


  }

  
  @media (min-width: 425px) {
    .colonne{
      flex-direction: row;
    }
    
  }


}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


